.nav-tabs {

    .nav-link.active {
        border-bottom: 3px solid;
    }
}

/* FROM https://stackoverflow.com/a/48224232 */
.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}
