// Custom variables
@import "custom/variables";

// Bootstrap
//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./custom-bootstrap";

$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";

// Custom components

@import "custom/pages/code";
@import "custom/leader";
@import "custom/landingpage";
@import "custom/headers";
@import "custom/materialbox";
@import "custom/type";
@import "custom/icons";
@import "custom/links";
@import "custom/buttons";
@import "custom/images";
@import "custom/nav";
@import "custom/navbar";
@import "custom/pagination";
@import "custom/utilities";
@import "custom/fade-from-gray";
