html {
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: 1px;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: $font-family-sans-serif;
  letter-spacing: normal;
}

@include media-breakpoint-down(sm) {
  .display-1 {
    font-size: 3rem;
  }

  .display-2 {
    font-size: 2.75rem;
  }

  .display-3 {
    font-size: 2.5rem;
  }

  .display-4 {
    font-size: 2.25rem;
  }
}

.lead {
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

ul {
  list-style-type: square;
}

.quote {
  font-size: 1.15rem;
  font-weight: 300;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

.blockquote {
  margin-left: 3rem;
  position: relative;
  font-family: $font-family-serif;
  font-style: italic;
  &.blockquote-small {
    font-size: 0.9em;
    &.float-right,
    &.float-left {
      margin-left: 1.5rem;
      &::before {
        font-size: 1.5rem;
        line-height: 1.5;
        height: 1.5rem;
        width: 1.5rem;
        left: -1.5rem;
      }
    }
  }

  &::before {
    background-color: theme-color("primary");
    display: block;
    color: theme-color("black");
    content: "\201C";
    font-size: 1.75rem;
    font-weight: normal;
    line-height: 1.5;
    height: 1.75rem;
    width: 1.75rem;
    left: -3rem;
    position: absolute;
    text-align: center;
    top: 0;
  }

  .blockquote-footer {
    font-weight: normal;
  }

  &.text-center {
    margin-left: 0;

    &::before {
      margin: 0 auto 1rem;
      position: static;
    }
  }

  &.text-right {
    margin-left: 0;
    margin-right: 3rem;

    &::before {
      content: "\201D";
      left: inherit;
      right: -3rem;
    }
  }
}

cite {
  font-style: normal;
}
