.material-icons {
    font-size: 1rem;
}

.icon-lg {
    font-size: 1.5rem;
}

.icon-2x {
    font-size: 2rem;
}

.icon-3x {
    font-size: 3rem;
}

.icon-5x {
    font-size: 5rem;
}
