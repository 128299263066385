a {
    transition: color 0.25s ease-in-out;

    .bg-white &,
    .bg-light &,
    .bg-primary & {
        color: theme-color("black");
    }
}

p {

    a {
        border-bottom: 1px solid theme-color("white");
        box-shadow: inset 0 -1px 0 0;
        transition: all 0.25s ease-out;

        &:hover {
            box-shadow: inset 0 -1.75rem 0 0 theme-color("white");
            color: theme-color("black");
        }

        .bg-white &,
        .bg-light &,
        .bg-primary & {
            border-color: theme-color("black");

            &:hover {
                box-shadow: inset 0 -1.75rem 0 0 theme-color("black");
                color: theme-color("white");
            }
        }
    }
}
