.claims {
  .claim {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2em;
  }

  .claim-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .materialbox {
    font-weight: bold;
    text-align: center;
  }
}
