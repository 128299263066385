.avatar {
    border-radius: 50%;
}

.avatar-sm {
    width: 48px;
    height: 48px;
}

.avatar-md {
    width: 64px;
    height: 64px;
}

.avatar-lg {
    width: 96px;
    height: 96px;
}

.img-thumbnail,
.img-thumbnail + .img-hover {
    border-radius: 1rem;
}

.img-color {

    img {
        filter: grayscale(100%);
        transition: all 0.4s ease-in-out;
    }

    &:hover {

        img {
            filter: none;
        }
    }
}

.img-overlay {
    position: relative;

    .img-hover {
        background-color: rgba(0,0,0,0.7);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
    }

    .img-text {
        color: theme-color("white");
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        transform: translateY(10px);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    &:hover  {

        .img-hover,
        .img-text {
            opacity: 1;
        }

        .img-text {
            transform: translateY(0);
        }
    }
}

.img-rising {

    img {
        transition: all 0.3s ease-in-out;
    }

    &:hover img {
        box-shadow: $box-shadow;
        transform: translateY(-5px);
    }
}

.img-scale {
    overflow: hidden;
    position: relative;

    .img-wrap {
        overflow: hidden;
        width: calc(100% + 1px);
    }

    img {
        transform: scale(1);
        transition: transform 0.3s ease;
    }

    &:hover img {
        transform: scale(1.1);
    }
}
