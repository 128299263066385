.materialbox {
  background: #fff;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  img {
    margin: 0 auto;
    max-width: 80%;
    display: block;
  }
}
