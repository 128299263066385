// Border width

@for $i from 1 through 3 {
    .border-#{$i} {
        border-width: ($i / 4) + rem !important;
    }
}

// Responsive borders

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {

        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .border#{$infix}-right {
            border-right: $border-width solid $border-color;
        }

        .border#{$infix}-left {
            border-left: $border-width solid $border-color;
        }

        .border#{$infix}-right-0 {
            border-right: 0 !important;
        }

        .border#{$infix}-left-0 {
            border-left: 0 !important;
        }

        .border#{$infix}-x {
            border-left: $border-width solid $border-color;
            border-right: $border-width solid $border-color;
        }

    }
}
