//@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.head {
  color: $white;
}

.picture-head {
  position: relative;
  padding-top: 30vh;
  padding-bottom: 15vh;
  @include media-breakpoint-down(md) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }

  .picture {
    z-index: -1000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;

    filter: grayscale(100%) contrast(120%) brightness(30%);
  }

  &.picture-head-hover-effect {
    .picture {
      transition: ease-in-out 400ms;
    }
    &:hover .picture {
      filter: grayscale(0%) contrast(120%) brightness(40%);
    }
  }
}

.video-head {
  padding-top: 30vh;
  padding-bottom: 15vh;
  @include media-breakpoint-down(md) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  position: relative;
  .video-container {
    position: absolute;
    z-index: -1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;

    video {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%) translateY(-20%);
    }
  }
}

.code-head {
  padding-top: 25vh;
  padding-bottom: 10vh;
  @include media-breakpoint-down(md) {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  position: relative;
  .code-container {
    position: absolute;
    z-index: -1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    background: black;
    code {
      @include media-breakpoint-down(md) {
        font-size: 0.5em;
      }
      filter: blur(2px);
      -webkit-font-smoothing: none;
      -moz-osx-font-smoothing: none;
      color: #fe2ef8;
      min-width: 100%;
      overflow: hidden;
      max-height: 100%;
      position: absolute;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
